import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <section className="f-wrapper">
      <div className="paddings innerWidth flexCenter f-container">
        {/* left side */}
        <div className="flexColStart f-left">
          <img src="./logo.jpg" alt="logo picture" width={120} />
          
          <span className="secondaryText">
            Our vision is to empower everyone to achieve their dream property.
          </span>
        </div>

        

        {/* right side */}
        <div className="flexColStart f-right">
          <span className="primaryText">Address</span>
          <span className="secondaryText">New Delhi, Delhi</span> 
        </div>
        {/* <div className="flexCenter f-menu">
        <div className="flexColStart f-right">
          <span className="primaryText">Address</span>
          <span className="secondaryText">New Delhi, Delhi</span> 
        </div>
            <button className="button">Top</button>
            
          </div> */}
      </div>
    </section>
  );
};

export default Footer;
