import React, { useState } from "react";
import "./Header.css";
import { BiMenuAltRight } from "react-icons/bi";
import OutsideClickhandler from "react-outside-click-handler";

function Header() {
  const [menuOpened, setMenuOpened] = useState(false);
  const getMenuStyles = (menuOpened) => {
    if (document.documentElement.clientWidth <= 800) {
      return { right: !menuOpened && "-100%" };
    }
  };
  return (
    <section id="header-id" className="h-wrapper">
      <div className="flexCenter paddings innerWidth h-container">
        <img src="./logo.jpg" alt="logo" width={100} />
        <OutsideClickhandler
          onOutsideClick={() => {
            setMenuOpened(false);
          }}
        >
          <div className="flexCenter h-menu" style={getMenuStyles(menuOpened)}>
            <a href="#residency-id">Residencies</a>
            <a href="#value-id">Our Value</a>
            <a href="#contact-us">Contact Us</a>
            <a href="#get-started-id">Get Started</a>
            {/* <button className="button">Contact</button> */}
          </div>
        </OutsideClickhandler>
        <div
          className="menu-icon"
          onClick={() => setMenuOpened((prev) => !prev)}
        >
          <BiMenuAltRight size={30} />
        </div>
      </div>
    </section>
  );
}

export default Header;
