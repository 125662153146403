import React from "react";
import "./GetStarted.css";

const GetStarted = () => {
  return (
    <section id="get-started-id" className="g-wrapper">
      <div className="paddings innerWidth g-container">
        <div className="flexColCenter innner-container">
          <span className="primaryText">Get Started With HomeHaven</span>
          <span className="secondaryText">
            Subscribe and find best properties at lucarative rates
            <br />
            <br />
            Click on the button below to mail us your property requirements and we will do our best to meet your needs.
          </span>

          <button className="button">
            <a href="mailto:shadil.farooqui@gmail.com">Get Started</a>
          </button>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;
